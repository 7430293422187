<template>
    <div>
        <van-row class="item-card" v-for="(item,index) in data" v-bind:key="index" @click="cardClick(item)">
            <van-col offset="1" span="23">
                <van-row class="title van-ellipsis">
                    <van-col span="20" class="van-ellipsis">{{ item.titleName }}</van-col>
                    <van-col span="4" v-if="item.isRead == 'N'" class="is-read">未读
                    </van-col>
                </van-row>
                <van-row v-if="item.pushType" class="info">
                  推送类型：{{item.pushType}}
                </van-row>
                <van-row v-if="item.pushPerson" class="info">
                    推送人：<span class="push-person">{{item.pushPerson}}</span>
                </van-row>
                <van-row v-if="item.pushDate" class="info">
                    推送日期：{{item.pushDate}}
                </van-row>
                <van-row v-if="item.content" class="info content">
                  <van-col span="21">
                    推送内容：{{item.content}}
                  </van-col>
                </van-row>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "InfoCard",
        props: {
            data: {type: Array}
        },
        data() {
            return {}
        },
        methods: {
          cardClick(item) {
            console.log(item)
            if (item && item.to && item.to.length > 0) {
              this.$emit('cardClickCallBack', item);
            }
          },
        },
        watch: {}
    }
</script>

<style scoped>
    .push-person {
        color: #007fff !important;
    }

    .item-card {
        background: #fff;
        padding: 5px 0;
        margin-top: 1px;
        text-align: left;
    }

    .title {
        font-size: 16px;
        color: #007fff;
        margin-top: 10px;
    }

    .info {
        padding: 5px 0;
        font-size: 10px;
        color: #999999;
    }

    .content{
      font-size: 10px;
    }

    .is-read {
      font-size: 18px;
      font-weight: bolder;
      margin-top: 5px;
      color: red;
    }
</style>
