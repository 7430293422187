<template>
  <div class="grp-fzr-card">
    <van-popup
        v-model="showPopup"
        position="top"
        :style="{ height: '62%' }"
    >
      <div>
        <van-row span="24" class="pickTitle">
          <van-col offset="0" span="7">客户负责人</van-col>
        </van-row>
        <van-row span="24" class="pickArea">
          <div v-for="(item, index) in data" v-bind:key="item.id">
            <van-col offset="1" span="7" class="pickArea"
                     v-for="(item1, index1) in item.users" v-bind:key="item1.id">
              <div class="pickDiv"
                   :style="{color: item1.use ? item1.color : 'black', background: item1.use ? item1.backColor: '#F3F3F3'}"
                   @click="selectFzr(item1,index1)">
                {{ item1.text }}
              </div>
            </van-col>
          </div>
        </van-row>
        <van-row span="24" class="pickArea">
          <van-col span="12">
            <van-button size="large" type="default" @click="onClear()">取消</van-button>
          </van-col>
          <van-col span="12">
            <van-button class="pickBtn" size="large" type="default" @click="confirm()">确定
            </van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import {Row, Col, Cell} from 'vant'

Vue.use(Row).use(Col).use(Cell)
export default {
  name: "FzrCard",
  components: {},
  props: {
    data: {type: Array},
    btn: {type: Boolean}
  },
  data() {
    return {
      showPopup: false,
      pickGrp: 'null',
      pickcFzr: 'null',
    }
  },
  mounted() {
  },
  methods: {
    /*清空所选内容*/
    onClear() {
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].use = false;
        for (let j = 0; j < this.data[i].users.length; j++) {
          this.data[i].users[j].use = false;
        }
      }
      this.pickGrp = 'null'
      this.pickcFzr = 'null'
      this.showPopup = false
      this.$emit("group", this.pickGrp, this.pickcFzr);
    },
    confirm() {
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].use = false;
        for (let j = 0; j < this.data[i].users.length; j++) {
          this.data[i].users[j].use = false;
        }
      }
      this.showPopup = false
      this.$emit("group", this.pickGrp, this.pickcFzr);
    },
    showGrpFzr() {
      this.showPopup = true
      this.onClear()
    },
    selectGroup(item, index) {
      this.pickGrp = '';
      console.log(item)
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].use = false;
      }
      item.use = true;
      this.pickGrp = item.id
    },
    selectFzr(item, index) {
      this.pickcFzr = '';
      for (let i = 0; i < this.data.length; i++) {
        for (let j = 0; j < this.data[i].users.length; j++) {
          this.data[i].users[j].use = false;
        }
      }
      item.use = true;
      this.pickcFzr = item.pid
    }
  }
}
</script>

<style scoped>
.grp-fzr-card .pickTitle {
  margin-top: 20px;
  font-weight: bolder;
  font-family: 华文宋体
}

.grp-fzr-card .pickArea {
  margin-top: 10px;
  font-size: 10px
}

.grp-fzr-card .pickDiv {
  width: 100px;
  height: 36px;
  background-color: #F8F8F8;
  text-align: center;
  line-height: 36px;
  vertical-align: middle
}

.grp-fzr-card .pickBtn {
  background-color: #3AADFF
}
</style>