<template>
  <div class="leader-official-panel">
    <van-tabs v-model="activeName" @click="onClick" border sticky :swipeable=false>
      <van-tab name="03" style="overflow-y: auto;background-color: white">
        <div slot="title">
          复盘批复
          <span v-if="circle1 != 0" class="circle">+{{ circle1 }}</span>
        </div>
        <van-row>
          <van-col span="5" style="margin-top: 20px;padding-left: 10px">
            <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
            <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
          </van-col>
          <van-search v-model="selectValue" placeholder="请输入走访名称" @cancel="cancel()" show-action/>
        </van-row>
        <div v-if="this.list && this.list.length>=1">
          <info-card :data="this.list" style="margin-bottom: 50px" @cardClickCallBack="cardClickCallBack"/>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
        </div>
      </van-tab>
      <van-tab name="08" style="overflow-y: auto;;background-color: white">
        <div slot="title">
          问题批复
          <span v-if="circle2 != 0" class="circle">+{{ circle2 }}</span>
        </div>
        <van-row style="background-color: white">
          <van-col span="5" style="margin-top: 20px;padding-left: 10px">
            <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
            <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
          </van-col>
          <van-search v-model="selectValue" placeholder="请输入走访名称" @cancel="cancel()" show-action/>
        </van-row>
        <div v-if="this.list && this.list.length>=1">
          <info-card :data="this.list" style="margin-bottom: 50px" @cardClickCallBack="cardClickCallBack"/>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
        </div>
        <!--        <el-empty description="待开发"></el-empty>-->
      </van-tab>
    </van-tabs>
    <div v-if="activeName!='08'" :class="btn?'iconStyle':'iconStyle1'" @mouseleave="removeActive($event)"
         :style="{height: activeName=='08'?'58px':'30px'}">
      <van-col span="1" :style="{marginTop: activeName=='08'?'20px':'5px',marginLeft: '-18px'}">
        <van-icon :name="btn?'arrow':'arrow-left'" @click="btn=!btn"/>
      </van-col>
      <van-row>
        <van-col offset="4" span="19">
          <div
              class="icon"
              v-for="(item, index) in posOpt"
              @click="tabClick(item, index)"
              :key="index"
          >
            <div class="MinIconBox">
              <i :class="item.icon"></i> <span>{{ item.name }}</span>
            </div>
            <!--            <div v-if="this.activeName == '08'" class="MinIconBox">-->
            <!--              <i class="el-icon-time"></i> <span>一键延期</span>-->
            <!--            </div>-->
          </div>
        </van-col>
      </van-row>

    </div>
    <div v-else :class="btn?'iconStyle':'iconStyle1'" @mouseleave="removeActive($event)"
         :style="{height: activeName=='08'?'60px':'30px'}">
      <van-col span="1" :style="{marginTop: activeName=='08'?'20px':'5px',marginLeft: '-18px'}">
        <van-icon :name="btn?'arrow':'arrow-left'" @click="btn=!btn"/>
      </van-col>
      <van-row>
        <van-col offset="4" span="19">
          <div
              class="icon"
              v-for="(item, index) in posOpt1"
              @click="tabClick(item, index)"
              :key="index"
          >
            <div class="MinIconBox">
              <i :class="item.icon"></i> <span>{{ item.name }}</span>
            </div>
          </div>
        </van-col>
      </van-row>

    </div>
    <fzr-card :data="this.columns" ref="grpFzrCard" @group="grp(arguments)"></fzr-card>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import InfoCard from "@/components/Card/InfoCard";
import FzrCard from "@/components/Card/FzrCard";
import {Toast} from "vant";

export default {
  name: "LeaderOfficialPanel",
  components: {CrmTabBar, InfoCard, FzrCard},
  data() {
    return {
      activeName: '03',
      circle1: 0,
      circle2: 0,
      btn: false,
      posOpt: [
        {name: "一键批复", code: 0, icon: "el-icon-circle-check"},
      ],
      posOpt1: [
        {name: "已解决", code: '03', icon: "el-icon-circle-check"},
        {name: "持续跟进", code: '01', icon: "el-icon-time"},
      ],
      isAll: false,
      list: [],
      freshHeight: 0,
      selectValue: '',
      selectValue1: '',
      showPopup: false,
      fzr: '',
      //组办下拉列表数据源
      columns: [
        {
          values: '',
          id: 'R00311',
          className: 'column1',
          text: '燕钢项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17039',
              className: 'column1',
              text: '李鹏飞',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12005',
              className: 'column1',
              text: '李阳',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13117',
              className: 'column1',
              text: '王立永',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            },
          ]
        },
        {
          values: '',
          id: 'R00258',
          className: 'column2',
          defaultIndex: 0,
          text: '散-总经办',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '05001',
              className: 'column2',
              text: '赵永鹏',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '04008',
              className: 'column2',
              text: '张鸿凯',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00030',
          className: 'column3',
          defaultIndex: 0,
          text: '散-现场组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '12066',
              className: 'column3',
              text: '张强',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00318',
          className: 'column4',
          defaultIndex: 0,
          text: '钢铁华南项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '21196',
              className: 'column4',
              text: '余蜀东',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00202',
          className: 'column5',
          defaultIndex: 0,
          text: '钢铁东北组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18198',
              className: 'column5',
              text: '王泽民',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '17032',
              className: 'column5',
              text: '马乾博',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '16166',
              className: 'column5',
              text: '孙男',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13006',
              className: 'column5',
              text: '迟海祥',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '21014',
              className: 'column5',
              text: '陈东宝',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12007',
              className: 'column5',
              text: '马兆磊',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18061',
              className: 'column5',
              text: '白也',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00301',
          className: 'column6',
          defaultIndex: 0,
          text: '钢煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17223',
              className: 'column6',
              text: '高龙',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00302',
          className: 'column7',
          defaultIndex: 0,
          text: '电煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16086',
              className: 'column7',
              text: '王帅',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00080',
          className: 'column8',
          defaultIndex: 0,
          text: '船管租船组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16034',
              className: 'column8',
              text: '刘小恺',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00307',
          className: 'column9',
          defaultIndex: 0,
          text: '长协项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '13072',
              className: 'column9',
              text: '张航',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18215',
              className: 'column9',
              text: '翟成基',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00170',
          className: 'column10',
          defaultIndex: 0,
          text: '(散)粮食组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18005',
              className: 'column10',
              text: '许小庆',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '14018',
              className: 'column10',
              text: '王杨',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
      ],
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  watch: {
    //监听负责人名头
    'selectValue': function () {
      this.list = [];
      this.init();
    },
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/cusPush/getPushConcernInfo.json',
          {
            pushType: this.activeName,
            // pushPerson: this.$store.getters.userId === '05001' || this.$store.getters.userId === '04002' || this.$store.getters.userId === '19060' ? this.fzr : this.$store.getters.userId,
            pushPerson: this.fzr === '' ? this.$store.getters.userId : this.fzr,
            fzr: this.fzr,
            titleName: this.selectValue
          }).then(res => {
        if (res.status == 200) {
          this.list = res.data
          console.log(this.list)
        }
        if (res.data.length > 0) {
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
      this.$ajax.post('/api/ajax/cusPush/getPushCountInfoDetail1.json',
          {
            pushPerson: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          this.circle1 = res.data[0].count
          this.circle2 = res.data[0].count1
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onClick() {
      this.selectValue = ''
      this.selectValue1 = ''
      this.fzr = ''
      this.init(this.activeName)
    },
    cancel() {
      this.selectValue = ''
      this.selectValue1 = ''
      this.fzr = ''
      this.init(this.activeName)
    },
    showGrpFzr() {
      this.$refs.grpFzrCard.showPopup = true
      this.$refs.grpFzrCard.pickGrp = 'null'
      this.$refs.grpFzrCard.pickcFzr = 'null'
      this.showPopup = true
    },
    zcr(msg) {
      // data为子页面的传过来的数据
      console.log(msg[0], msg[1], msg[2])
      this.pickZcr = msg[2] != 'null' ? msg[2] : ''
      this.init()
    },
    grp(msg) {
      // data为子页面的传过来的数据
      if (msg[1] != 'null') {
        console.log(msg[1])
        this.fzr = msg[1]
      } else {
        this.fzr = ''
      }
      this.init()
    },
    cardClickCallBack(item) {
      console.log('customer', item)
      if (this.$store.getters.userId == '05001' || this.$store.getters.userId == '19060' || this.$store.getters.userId == '12064' || this.$store.getters.userId == '18280' || this.$store.getters.userId == '04002') {
        this.$router.push({path: item.to, query: {}})
      }
    },
    tabClick(item, index) {
      if (this.$store.getters.userId == '05001' || this.$store.getters.userId == '19060') {
        if (this.list.length == 0) {
          this.$message.warning('暂无数据！');
        } else {
          //判断code 状态 ，如果等于0，点击后就滑动到id为(#floor0)的锚点内容区域，也就是根据code状态码 展示对应的锚点
          this.$prompt('请输入评价', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /^.+$/,
            inputErrorMessage: '请输入内容。。。',
            customClass: 'msgbox'
          }).then(({value}) => {
            console.log(this.list)
            if (this.activeName == '03') {
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].isRead == 'Y') {

                } else {
                  this.$ajax.post('/api/ajax/replay/updateBulkCrmReplayAll.json',
                      {
                        titleId: this.list[i].titleId,
                        allOfficial: value,
                        createdPeople: this.$store.getters.userId
                      }).then(res => {
                    if (res.status == 200) {
                    }
                  }).catch(err => {
                    console.log(err)
                    // this.$message.error('保存失败！');
                  })
                }
                if (i == this.list.length - 1) {
                  this.$message.success('批复成功！');
                  this.$router.push({path: '/index'})
                }
              }
            } else {
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].isRead == 'Y') {

                } else {
                  this.$ajax.post('/api/ajax/problerm/updateProblermSchemeAll.json',
                      {
                        id: this.list[i].titleId,
                        status: item.code,
                        modifiedPeople: this.$store.getters.userId,
                        official: value
                      }).then(res => {
                    if (res.status == 200) {
                    }
                  }).catch(err => {
                    console.log(err)
                    // this.$message.error('保存失败！');
                  })
                }
                if (i == this.list.length - 1) {
                  this.$message.success('批复成功！');
                  this.$router.push({path: '/index'})
                }
              }
            }
          }).catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '取消输入'
            // });
          });
        }

      } else {
        this.$message.warning('只允许领导批复！');
      }
    },
    //鼠标移出事件
    removeActive() {
      this.btn = false
    },

  }
}
</script>

<style scoped>
.iconStyle {
  list-style: none;
  position: fixed;
  padding: 10px 10px 10px 10px;
  top: 40%;
  right: -132px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: -1px 2px 5px #d5cfcf;
  font-size: 8px;
  font-weight: 400;
  color: #273356;
  transition: 0.5s;
}

.iconStyle1 {
  list-style: none;
  position: fixed;
  padding: 10px 10px 10px 10px;
  top: 40%;
  right: -1px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: -1px 2px 5px #d5cfcf;
  font-size: 8px;
  font-weight: 400;
  color: #273356;
  transition: 0.5s;
}

.iconStyle .icon {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #d9d9d9;

}

.iconStyle .MinIconBox {
  width: 140px;
  display: flex;
  align-items: center;

}

.iconStyle i {
  width: 30px;
  font-size: 23px;
  margin-left: 10px;
}

.iconStyle span {
  font-size: 16px;
}

.iconStyle .icon :hover {
  color: #fff;
  background-color: #1890ff;
}

.iconStyle:hover {
  right: 0;
}


.iconStyle1 .icon {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #d9d9d9;

}

.iconStyle1 .MinIconBox {
  width: 0px;
  display: flex;
  align-items: center;
}

.iconStyle1 i {
  width: 0px;
  font-size: 23px;
  margin-left: 10px;
}

.iconStyle1 span {
  font-size: 16px;
}

.iconStyle1:hover {
  right: 0;
}
</style>
<style>
.msgbox {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .msgbox {
    width: 420px;
  }
}
</style>

<style>
.leader-official-panel .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}

.leader-official-panel .circle {
  border-radius: 100%;
  background-color: red;
  padding: 2px;
}
</style>